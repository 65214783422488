<template>
  <div>
    <CCard>
      <Device ref="deviceView" @change="onChangedSite" prefix="btfs_"/>
    </CCard>

    <Event ref="eventView" @click="onClickEvent" prefix="btfs_"/>

    <EventDialog ref="eventDialog" />
  </div>
</template>

<script>
import Device from "@/views/dashboard/Device";
import EventDialog from "@/views/dashboard/EventDialog";
import Event from "@/views/device/Event";
import utils from "@/services/utils";

export default {
  name: "Dashboard",
  components: {
    Device,
    EventDialog,
    Event,
  },
  created: function () {
    this.user = this.$store.state.auth.user_info.user;
    this.sites = this.$store.state.auth.user_info.sites;

    var userInfo = utils.getStorage("userInfo");
  },
  data() {
    return {
      selected_row: null,
      selected_site_devices: [],
    };
  },
  methods: {
    onClick(item) {
      // console.log('Dashboard::onClick Summation click item:', JSON.stringify(item,null,2))
      var eventTable = this.$refs.eventView.getTable();
      this.$refs.eventDialog.show(item, eventTable.data);
    },
    onClickEvent(field, value, row) {
      this.$refs.deviceView.onEvent(field);
    },
    onChangedSite(siteTable) {
      // console.log('Dashboard::onChangedSite siteTable:', siteTable)
      this.selected_site_devices = siteTable;
      var eventTable = this.$refs.eventView.getTable();
      eventTable.data.forEach((event) => {
        // console.log('Dashboard::onChangedSite setMakerImage(event):', event)
        this.$refs.deviceView.setMarkerImage(event);
      });
    },
  },
};
</script>
